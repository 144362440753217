import {createSlice} from '@reduxjs/toolkit'
import { getDetailPageData } from '../../actions/detailPage/detailPage';

const initialState = {
    loading: false,
    error: false,
    detail:[],
    message: null,
  };

const detailPageSlice = createSlice({
    name:'detail',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Notice
      builder.addCase(getDetailPageData.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getDetailPageData.fulfilled, (state, action) => {
        state.loading = false;
        state.detail = action.payload;
        state.error = "";
      });
      builder.addCase(getDetailPageData.rejected, (state, action) => {
        state.loading = false;
        state.detail = [];
        state.error = action.error.message;
      });
    }
})

export default detailPageSlice.reducer