import { useSortable } from "@dnd-kit/sortable";
import AppCard from "../index";

function SortableAppCard({
  key,
  app,
  isDashboard,
  userId,
  isFavorite,
  onFavoriteChange,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: app && app.appId });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
        transition: transition || undefined,
        opacity: isDragging ? 0.5 : 1,
        cursor: "grab",
        ...attributes.style,
      }}
      {...listeners}
      {...attributes}
    >
      <AppCard
        key={key}
        app={app}
        isFavorite={isFavorite}
        onFavoriteChange={(key, isFav) => onFavoriteChange(key, isFav)}
        userId={userId}
        isDashboard={isDashboard}
      />
    </div>
  );
}

export default SortableAppCard;
