import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    custom_editor: {
      "& p": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
    },
    linkText: {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
    backArrow: {
      display: "flex",
      position: "absolute",
      alignItems: "center",
      top: "105px",
      left: "24px",
    },
    backTxt: {
      fontFamily: "Montserrat-Regular",
      color: theme.palette.common.white,
      fontSize: "16px",
      fontWeight: "600",
      letterSpacing: "1px",
      paddingLeft: "8px",
    },
    BannerItem1: {
      position: "relative",
      paddingLeft: "105px !important",
      paddingTop: "60px !important"
    },
    BannerHeading: {
      paddingLeft: "25px",
      color: theme.palette.common.white,
      fontFamily: "Oswald-Regular",
      fontSize: "46px",
      marginBottom: "0px",
      fontWeight: "500",
      [theme.breakpoints.only('xs')]: {
        width: '230px',
        fontSize: '32px'
      }
    },
    BannerItem2: {
      fontFamily: "Montserrat-Regular",
     // width: "750px",
      //height: "85px",
      fontSize: "16px",
      color: theme.palette.common.white,
      paddingTop: "85px",
      paddingLeft: "40px",
      lineHeight: "18px",
      opacity: "0.8",
      [theme.breakpoints.only('xs')]: {
        display: 'none',
      }
    },
    relatedContentBox: {
      height: "330px",
      backgroundColor: "#cdebff4d",
    },
    quickLinksDynamicHtmlBox: {
      height: "auto",
      padding: "30px 30px 30px 30px",
      overflowX: "scroll"
    },
    quickLinksResponsiveContainer: {
      // [theme.breakpoints.only('xs')]: {
      //   display: 'block',
      // }
    },
    quickLinksDynamicHtmlBoxGrid1: {
      paddingRight: "50px",
      "& p": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
        textAlign: "justify"
      },
      "& span": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
      "& h2": {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        fontFamily: "Oswald-Regular",
      },
      "& li": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
      "& table": {
        fontFamily: "Montserrat-Regular",
        borderCollapse: "collapse",
        fontSize: "18px",
        color: theme.palette.info.main,
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
      },
      "& td": {
        borderBottom: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
        fontFamily: "Montserrat-Regular",
      },
      "& tr": {
        borderBottom: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
        fontFamily: "Montserrat-Regular",
        color: '#333',
      },
      "& th": {
        borderBottom: "1px solid #404246",
        textAlign: "left",
        padding: "8px",
        color: '#333',
        fontFamily: "Montserrat-Regular",
      },
      "& tr:nth-child(even)": {
        backgroundColor: "#fff",
      },
      "& blockquote": {
        background: "#f1f9fe",
        borderLeft: `10px solid ${theme.palette.primary.main}`,
        margin: "1.5em 10px",
        padding: "0.5em 10px",
        // quotes: "\201C""\201D""\2018""\2019",
      },
      "& blockquote:before": {
        color: theme.palette.common.white,
        // content: "open-quote",
        fontSize: "4em",
        lineHeight: "0.1em",
        marginRight: "0.25em",
        verticalAlign: "-0.4em",
      },
      "& blockquote p": {
        // display: "inline"
      },
    },
    quickLinksDynamicHtmlBoxGrid2: {
      paddingLeft: "50px",
      "& h1": {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        fontFamily: "Oswald-Regular",
      }
    },
    dynamicHtmlBox: {
      minHeight: "52vh",
      padding: "30px 80px 80px 80px",
      overflowX: "scroll",
      "& p": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
        textAlign: "justify"
      },
      "& span": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
      "& h2": {
        color: theme.palette.secondary.main,
        fontSize: "32px",
        fontFamily: "Oswald-Regular",
      },
      "& li": {
        color: theme.palette.info.main,
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: "Montserrat-Regular !important",
        fontSize: "18px !important",
        fontWeight: "300 !important",
        lineHeight: "32px !important",
      },
      "& table": {
        fontFamily: "Montserrat-Regular",
        borderCollapse: "collapse",
        fontSize: "18px",
        color: theme.palette.info.main,
        width: "100%",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
      },
      "& td": {
        borderBottom: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
        fontFamily: "Montserrat-Regular",
      },
      "& tr": {
        borderBottom: "1px solid #dddddd",
        textAlign: "left",
        padding: "8px",
        fontFamily: "Montserrat-Regular",
        color: '#333',
      },
      "& th": {
        borderBottom: "1px solid #404246",
        textAlign: "left",
        padding: "8px",
        color: '#333',
        fontFamily: "Montserrat-Regular",
      },
      "& tr:nth-child(even)": {
        backgroundColor: "#fff",
      },
      "& blockquote": {
        background: "#f1f9fe",
        borderLeft: `10px solid ${theme.palette.primary.main}`,
        margin: "1.5em 10px",
        padding: "0.5em 10px",
        // quotes: "\201C""\201D""\2018""\2019",
      },
      "& blockquote:before": {
        color: theme.palette.common.white,
        // content: "open-quote",
        fontSize: "4em",
        lineHeight: "0.1em",
        marginRight: "0.25em",
        verticalAlign: "-0.4em",
      },
      "& blockquote p": {
        // display: "inline"
      },
    },
    dynamicHtmlBox2: {
      minHeight: "60px",
      paddingTop: "20px",
      overflowX: "scroll"
    },
    relatedContentHeading: {
      color: theme.palette.secondary.main,
      fontFamily: "Oswald-Regular",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      margin: "0px",
      paddingLeft: "30px",
    },
    QuickLinksHeading: {
      paddingLeft: "50px",
      color: theme.palette.secondary.main,
      fontFamily: "Oswald-Regular",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      margin: "0px",
      paddingLeft: "30px",
    },
    QuickLinksUrl: {
      padding: '15px 0px 15px 0px',
      fontFamily: "Montserrat-Regular",
      fontSize: '20px'
    },
    QuickLinksUrlBox: {
      paddingTop: '10px',
      paddingLeft: '30px'
    },
    QuickLinksUrlFont: {
      color: theme.palette.primary.main
    },
    cardSize: {
      maxWidth: 345,
      height: 250,
    },
    relatedContentCrdTxt: {
      color: theme.palette.info.main,
      letterSpacing: "1px",
    },
    relatedContentCrdHeading: {
      color: "#183750",
      fontFamily: "Oswald-Regular !important",
      fontWeight: "900 !important",
      lineHeight: "24px !important",
      fontSize: "16px !important",
      width: "233px !important",
    },
    cardBackcolor: {
      backgroundColor: "#cdebff4d",
    },
    bannerimgBox: {
      backgroundSize: "cover",
      objectFit: "cover",
      height: "100%",
      width: "100%",
      height: "300px",
    },
  }
}
);

export default styles;
