import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => {
  return {
    dialog: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .MuiDialog-container": {
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiPaper-root": {
          justifyContent: "center",
          alignItems: "center",
        },
        "& .MuiDialogTitle-root": {
          width: "95%",
        },
        "& .MuiDialogContent-root": {
          width: "90%",
          justifyContent: "center",
          "& .WordSection1 > p.MsoNormal": {
            display: "none",
            "& .MuiDialogTitle-root": {
              fontSize: "32px",
              color: theme.palette.secondary.main,
              fontFamily: "Oswald-Regular",
              fontWeight: 700,
              lineHeight: "36px",
            },
          },
        },
      },
    },
    title: {
      marginTop: theme.spacing(2),
      fontWeight: "700 !important",
      textAlign: "left",
      fontSize: "20px",
      color: `${theme.palette.secondary.main} !important`,
      fontFamily: "Oswald-Regular !important",
      lineHeight: "36px !important",
      width: "95%",
    },
    titleBig: {
      marginTop: theme.spacing(2),
      fontWeight: "700 !important",
      textAlign: "center",
      fontSize: "32px",
      color: `${theme.palette.secondary.main} !important`,
      fontFamily: "Oswald-Regular !important",
      lineHeight: "36px !important",
      width: "95%",
      [theme.breakpoints.down("md")]: {
        fontSize: "24px",
      },
    },
    subTitle: {
      color: theme.palette.info.main,
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "28px",
      fontFamily: "Montserrat-Regular",
      textAlign: "center",
      marginTop: theme.spacing(1),
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
    },
    description: {
      color: theme.palette.info.main,
      fontSize: "18px",
      fontWeight: 300,
      lineHeight: "32px",
      fontFamily: "Montserrat-Regular",
    },
    watchIcon: {
      paddingRight: theme.spacing(1),
      paddingTop: "3px",
    },
    dialogBoxTitle: {
      fontFamily: "Montserrat-Regular",
      fontWeight: "600",
      fontSize: theme.spacing(3),
      color: theme.palette.info.dark,
    },
    dialogBoxDescription: {
      fontFamily: "Montserrat-Regular",
      fontSize: theme.spacing(2),
      fontWeight: "600",
      color: theme.palette.info.main,
    },
    dialogBoxCancelButton: {
      fontFamily: "Montserrat-Regular",
      backgroundColor: theme.palette.common.white,
      fontSize: theme.spacing(2),
      fontWeight: "500",
      border: `1px solid ${theme.palette.common.outline}`,
      borderRadius: "24px",
      textTransform: "capitalize",
      margin: "8px",
      padding: "6px 16px",
    },
    dialogBoxActivityButton: {
      fontFamily: "Montserrat-Regular",
      fontSize: theme.spacing(2),
      fontWeight: "500",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "24px",
      textTransform: "capitalize",
      color: theme.palette.common.white,
      margin: "8px",
      padding: "6px 16px",
      '&:hover': {
        backgroundColor: theme.palette.primary.dark
      },
    },
  };
});

export default styles;
