import React, { useState } from "react";
import { Alert, Collapse, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import { useTheme } from "@mui/material/styles";
import "./style.scss";
import styles from "./styles";

const SiteAlerts = (props) => {
  const { type, title, description, closable, weight, onClose } = props;
  const [open, setOpen] = useState(true);

  const theme = useTheme();
  const { classes } = styles();

  let backgroundColor;
  let borderColor;
  switch (type) {
    case "error":
      backgroundColor = theme.palette.error.light;
      borderColor = theme.palette.error.dark;
      break;
    case "warning":
      backgroundColor = theme.palette.warning.light;
      borderColor = theme.palette.warning.dark;
      break;
    case "info":
      backgroundColor = theme.palette.primary.light;
      borderColor = theme.palette.primary.main;
      break;
    case "success":
      backgroundColor = theme.palette.success.light;
      borderColor = theme.palette.success.dark;
      break;
    default:
      backgroundColor = theme.palette.common.white;
      borderColor = theme.palette.info.main;
  }

  return (
    <div>
      <Collapse in={open}>
        <Alert
          severity={type}
          iconMapping={{
            info: (
              <NotificationImportantOutlinedIcon
                sx={{ color: theme.palette.info.main }}
                fontSize="inherit"
              />
            ),
            warning: (
              <WarningAmberOutlinedIcon
                sx={{ color: theme.palette.error.dark }}
                fontSize="inherit"
              />
            ),
            error: (
              <ErrorOutlineOutlinedIcon
                sx={{ color: theme.palette.error.dark }}
                fontSize="inherit"
              />
            ),
            success: (
              <TaskAltRoundedIcon
                sx={{ color: theme.palette.success.dark }}
                fontSize="inherit"
              />
            ),
          }}
          action={
            closable && (
              <IconButton
                aria-label="close"
                color={theme.palette.info.main}
                size="large"
                onClick={() => {
                  setOpen(false);
                  onClose();
                }}
                sx={{ padding: "0" }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{
            mb: 0.2,
            backgroundColor: backgroundColor,
            borderBottom: `1px solid ${borderColor}`,
          }}
          className={classes.siteAlert}
        >
          <span dangerouslySetInnerHTML={{ __html: description }}></span>
        </Alert>
      </Collapse>
    </div>
  );
};

export default SiteAlerts;
