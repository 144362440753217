import React, { useState } from "react";
import { Box } from "@mui/material";
import styles from "../styles";
import { Link } from "react-router-dom";

const SearchPagination = ({ totalResults, resultsPerPage, page, setPage }) => {
  const {classes} = styles();

  const handlePrevClick = () => {
    setPage(page - 1);
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };

  return (
    <div className={classes.paginationContainer}>
      <div
        className={classes.navigationButton}
        onClick={handlePrevClick}
        style={{ visibility: page > 0 ? "" : "hidden" }}
      >
        {"<"} Prev
      </div>

      <div className={classes.paginationText}>
        Page {page + 1} of {Math.ceil(totalResults / resultsPerPage)}
      </div>

      <div
        className={classes.navigationButton}
        onClick={handleNextClick}
        style={{
          visibility:
            page < Math.ceil(totalResults / resultsPerPage)-1 ? "" : "hidden",
        }}
      >
        Next {">"}{" "}
      </div>
    </div>
  );
};

export default SearchPagination;
