import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    root: {
      '& .MuiAccordion-root': {
        boxShowdow: 'none',
      }
    }
  }
}
);


export default styles;