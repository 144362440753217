import React, { useState, useEffect } from "react";
import { Card, CardContent, Box, useMediaQuery } from "@mui/material";
import styles from "./styles";
import "./style.scss";
import { msalConfig } from "./../../helper/auth";
import FavoriteIconButton from "./FavoriteIconButton";
import { Link } from "react-router-dom";
function AppCardMenu(props) {
  const { app, isFavorite, onFavoriteChange, handleCardClick, index, value, onMouseOver, onMouseLeave } = props;
  const { classes } = styles();
  const isMobile = useMediaQuery('(max-width:768px)');

  const handleFavoriteClick = () => {
    onFavoriteChange(app.appId, isFavorite); // Notify the parent component of the favorite change
  };
  const [showSubMenu, setShowSubMenu] = useState([]);


  return (

    <li style={{ width: "100%", height: "auto", display: "flex", justifyContent: "space-around" }} key={app.appId} onClick={handleCardClick} class="app-list" aria-haspopup="true">
      <Link onMouseOver={onMouseOver} style={{ display: "inline-block", width: "75%", borderBottom: "1px solid #E7E7E7" }}
        to={`https://launcher.myapps.microsoft.com/api/signin/${app.appId}?${msalConfig.auth.tenantId}`}
        //  href={`https://launcher.myapps.microsoft.com/api/signin/${app.appId}?${msalConfig.auth.tenantId}`}
        target="_blank"
        //className="menu-link mega-menu-link"
        className={`menu-link menu-list-link ${index == value ? "active" : ""}`}
        aria-haspopup="true"
      >  {app.displayName}
      </Link>
      <FavoriteIconButton
        aria-haspopup="true"
        isFavorite={isFavorite} // Pass isFavorite as a prop
        onClick={handleFavoriteClick} // Pass the handler function for favorite click
      />
      {!isMobile && <ul key={app.appId}
        animate={
          showSubMenu[app.appId] ? "open" : "closed"
        }
        className="menu menu-list thirdChild"
      >
        <Box
          component="div"
          key={app.appId}
          className="description"
          sx={{ pt: 4, px: 4, width: "90%" }}
        >
          <p><img hidden={undefined == app.info.logoUrl} style={{ width: 'auto', height: '50px' }} src={app.info.logoUrl} /></p>
          <p>Master the unknown with intelligent automation makes enterprise.</p>
        </Box>
      </ul>}
    </li>
  );
}

export default AppCardMenu;
