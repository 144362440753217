import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Card, CardActions, CardContent, Popover } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { postFeedback } from "../../redux/actions/Feedback/feedbackPost";
import "./style.scss";
import { useTheme } from "@mui/material/styles";
import styles from "./styles";
import CustomizedSnackbars from "../../components/Snackbar";

const Feedback = ({
  id,
  setOpen,
  openPop,
  anchorEl,
  onClose,
  feedback,
  oAuthToken,
}) => {
  const [feedbackText, setFeedbackText] = useState("");
  const dispatch = useDispatch();

  const { classes } = styles();
  const theme = useTheme();

  const feedbackQuestion = feedback["#title"];
  const feedbackKey = feedback["#webform_key"];

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };
  const handleSubmit = () => {
    // Here you can send the feedback data to your server or perform any other action
    if (oAuthToken && feedbackText) {
      setOpen(false);
      const feedbackPayload = {
        webform_id: feedbackKey,
        [feedbackKey]: feedbackText,
      };
      dispatch(postFeedback({ feedbackPayload, oAuthToken }));
    }
  };

  return (
    <>
      <Popover
        id={id}
        open={openPop}
        onClose={onClose}
        anchorEl={anchorEl}
        sx={{ height: "auto", width: "auto" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card className={classes.feedbackCard}>
          <CardContent>
            <div
              className={classes.feedbackCardContent}
              dangerouslySetInnerHTML={{ __html: feedbackQuestion }}
            ></div>
            <TextField
              autoFocus
              margin="dense"
              id="feedback"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={feedbackText}
              onChange={handleFeedbackChange}
              inputProps={{ maxLength: 250 }}
            />
          </CardContent>
          <CardActions className={classes.buttonContainer}>
            <Button
              variant="contained"
              className={classes.cancelButton}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className={classes.submitButton}
              onClick={handleSubmit}
            >
              Send Feedback
            </Button>
          </CardActions>
        </Card>
      </Popover>
    </>
  );
};

export default Feedback;
