// reducers/graphSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { fetchApps, getApplicationsAssignedToUser} from '../../actions/graph';

const initialState = {
  apps: [],
  loading: false,
  error: null,
};

const appSlice = createSlice({
  name: 'apps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Microsoft Apps
    // builder.addCase(fetchApps.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(fetchApps.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.apps = action.payload;
    //   state.error = "";
    // });
    // builder.addCase(fetchApps.rejected, (state, action) => {
    //   state.loading = false;
    //   state.apps = [];
    //   state.error = action.error.message;
    // });
    builder.addCase(getApplicationsAssignedToUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getApplicationsAssignedToUser.fulfilled, (state, action) => {
      state.loading = false;
      state.apps = action.payload;
      state.error = "";
    });
    builder.addCase(getApplicationsAssignedToUser.rejected, (state, action) => {
      state.loading = false;
      state.apps = [];
      state.error = action.error.message;
    });
  },
});

export default appSlice.reducer;
