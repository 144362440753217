import {createSlice} from '@reduxjs/toolkit'
import { getSearch } from '../../actions/Search/search';

const initialState = {
    loading: false,
    error: false,
    search:[],
    message: null,
    fetched:false,
  };

const searchSlice = createSlice({
    name:'search',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Notice
      builder.addCase(getSearch?.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getSearch?.fulfilled, (state, action) => {
        state.loading = false;
        state.fetched=true;
        state.search = action.payload;
        state.error = "";
        
      });
      builder.addCase(getSearch?.rejected, (state, action) => {
        state.loading = false;
        state.search = [];
        state.error = action.error.message;
      });
    }
})

export default searchSlice.reducer