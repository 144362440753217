import {createSlice} from '@reduxjs/toolkit'
import { getMegaMenu } from '../../actions/megaMenu/megaMenu';

const initialState = {
    loading: false,
    error: false,
    megaMenu:[],
    message: null,
  };

const megaMenuSlice = createSlice({
    name:'megaMenu',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Mega Menu
      builder.addCase(getMegaMenu.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getMegaMenu.fulfilled, (state, action) => {
        state.loading = false;
        state.megaMenu = action.payload;
        state.error = "";
      });
      builder.addCase(getMegaMenu.rejected, (state, action) => {
        state.loading = false;
        state.megaMenu = [];
        state.error = action.error.message;
      });
    }
})

export default megaMenuSlice.reducer