import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ isDashboard }) => {
  const theme = useTheme();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={isDashboard ? "10vh" : "70vh"}>
      {" "}
      <CircularProgress sx={{ color: isDashboard ? theme.palette.common.white : theme.palette.primary.main }} />{" "}
    </Box>
  );
};

export default Loader;
