import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import config from "../../../config";

export const getAutoComplete = createAsyncThunk(
  "/api/autocomplete",
  async ({searchQuery, oAuthToken}, { rejectWithValue }) => {
    try {
      const headers = {
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Basic ${basicAuthString}`, //`Bearer ${accessToken}` if access token authorization
          Authorization: `Bearer ${oAuthToken}`, //`Bearer ${accessToken}` if access token authorization
        },
      };
      const url =`${config.drupalapiBaseUrl}/api/v1/text_search?keys=${searchQuery}`;
      const response = await axios.get(url, headers);
      
      if (response.status === 200) {
        sessionStorage.setItem("refreshDialog", "false");
      }

      return response.data;
    } catch (error) {
      //throw error;
      // return custom error message from backend if present
      if (error.response.status) {
        sessionStorage.setItem("refreshDialog", "true");
      }
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
