import React, { useEffect, useState } from "react";
import { useTheme } from '@mui/material/styles';
import styles from "./styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Box, Grid, Button, Card, CardMedia, CardContent, Typography } from "@mui/material";
import slider2 from "../../assets/images/slider/slider2.png";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { Link } from "react-router-dom";

function SampleNextArrow(props) {
    const { className, slideCount, currentSlide, onClick } = props;
    const hasNextSlide = currentSlide < slideCount - 1;
    return (
        <div
            className={className}
            style={{
                position: "absolute",
                background: "transparent",
                right: "5% !important",
                zIndex: "999",
                height: "32px",
                width: "32px",
                color: 'black'
            }}
            onClick={onClick}
        >
            <ExpandCircleDownOutlinedIcon
                style={{
                    transform: "rotate(270deg)",
                    color: "#EEEEEE",
                    fontSize: "32px",
                    opacity: "0.4",
                    color: 'black'
                }}
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, currentSlide, onClick } = props;

    return (
        <div
            className={className}
            style={{
                position: "absolute",
                background: "transparent",
                left: "-5% !important",
                zIndex: "999",
                height: "32px",
                width: "32px",
            }}
            onClick={onClick}
        >
            <ExpandCircleDownOutlinedIcon
                style={{
                    transform: "rotate(90deg)",
                    color: "#EEEEEE",
                    fontSize: "32px",
                    opacity: "0.4",
                    color: 'black'
                }}
            />
        </div>
    );
}

export default function RelatedContentSlider(props) {
    const theme = useTheme();
    const { classes } = styles();
    const { relatedContents } = props;
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const renderRelatedContent = (card) => {
        return (
            <Box>
                <Link className={classes.linkStyle} to={card.detail_page_api_link} state={card}>
                    <Card className={classes.cardSize} elevation={0}>
                        {/* <CardMedia sx={{ height: 140 }} component="src" image={slider2} /> */}
                        <CardContent className={classes.cardBackcolor}>
                            <Typography
                                gutterBottom
                                className={classes.relatedContentCrdHeading}
                            >
                                {card.content_title}
                            </Typography>
                            <Typography className={classes.relatedContentCrdTxt}>
                                {card.content_summary}
                            </Typography>
                        </CardContent>
                    </Card>
                </Link>
            </Box>
        );
    };

    return (
        <div className={classes.sliderContainer}>
            <Slider {...settings}>
                {/* <Box> */}
                {relatedContents && relatedContents.map((card) => renderRelatedContent(card))}
                {/* </Box> */}
            </Slider>
        </div >)
};

