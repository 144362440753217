import React from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { Box } from "@mui/material";

export default function AttachmentViewer(props) {
    const fileUrl = props.attachment && props.attachment;
    const fileName = fileUrl && fileUrl.split('/').pop();
    const fileExtension = fileUrl && fileUrl.split('.').pop();
    const thumbnailUrl = props.thumbnail && props.thumbnail;
    const thumbnailName = thumbnailUrl && thumbnailUrl.split('/').pop();
    const thumbnailType = thumbnailUrl && thumbnailUrl.split('.').pop();

    const docs = [
        { uri: fileUrl, fileType: fileExtension, fileName: fileName }
    ];
    const thumbnailDocs = [
        { uri: thumbnailUrl, fileType: thumbnailType, fileName: thumbnailName }
    ];

    return (
        <>
            {thumbnailUrl &&
                <Box xs={12} sx={{ marginBottom: '60px' }}>
                    <DocViewer style={{ height: '600px', width: '100%' }} pluginRenderers={DocViewerRenderers} documents={thumbnailDocs} />
                </Box>
            }
            {fileUrl && fileExtension !== 'pdf' &&
                <Box xs={12} sx={{ marginBottom: '60px' }}>
                    <DocViewer style={{ height: '600px', width: '100%' }} pluginRenderers={DocViewerRenderers} documents={docs} />
                    {/* <iframe className={fileExtension} width="100%" height="600px" frameborder="0" title={fileName} src={`https://docs.google.com/gview?url=${fileUrl}&embedded=true`}></iframe> */}
                </Box>
            }
            {fileUrl && fileExtension === 'pdf' &&
                <Box xs={12} sx={{ marginBottom: '60px' }}>
                    <object data={fileUrl} type="application/pdf" width="100%" height="600px"></object>
                </Box>
            }
        </>
    );
}

