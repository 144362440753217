import React, { useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";

const GroupedAutocomplete = ({
  options,
  onChange,
  onKeyPress,
  onClick,
  handleBlur,
  handleFocus,
  value,
  setOpenDialog,
}) => {
  const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.secondary.main,
    backgroundColor:
      theme.palette.mode === "light"
        ? lighten(theme.palette.primary.main, 0.85)
        : darken(theme.palette.secondary.main, 0.8),
  }));
  const navigate = useNavigate();
  const GroupItems = styled("ul")({
    padding: 0,
  });

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus on the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const selectedOptionRef = useRef(null); // Using useRef to hold the currently selected option

  const handleKeyDown = (event) => {
    // Check if Enter is pressed and there is a selected option in the ref
    if (event.key === "Enter" && selectedOptionRef.current) {
      handleSelectedOption(selectedOptionRef.current);
    }
  };

  const handleSelectedOption = (option) => {
    setOpenDialog && setOpenDialog(false);
    if (option.categories === "Applications") {
      window.open(option.app_link_url, '_blank', 'noopener,noreferrer');
    } else {
      navigate(option.detail_page_api_link);
    }
  };
  return (
    <Autocomplete
      id="grouped-Search"
      open={value.length >= 3 && options.length > 0}
      popupIcon={<SearchIcon onClick={onClick} />}
      filterOptions={(x) => x}
      onBlur={handleBlur}
      onFocus={handleFocus}
      options={options} // Use options directly
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.title}
      onHighlightChange={(_, option) => {
        if (option) selectedOptionRef.current = option; // Update ref when an option is highlighted
      }}
      onChange={(_, newValue) => {
        if (newValue) handleSelectedOption(newValue);
      }}
      renderInput={(params, option) => (
        <TextField
          variant="standard"
          {...params}
          label="Search"
          InputLabelProps={{ style: { fontSize: "16px" } }} // Change the font size here
          onChange={onChange}
          onKeyPress={onKeyPress}
          onKeyDown={(event) => handleKeyDown(event)}
          inputRef={inputRef}
        />
      )}
      renderOption={(props, option) => (
        <li
          {...props}
          style={{ textDecoration: "none", color: "inherit" }}
          onClick={() => handleSelectedOption(option)}
          role="option"
          tabIndex={0}
          dangerouslySetInnerHTML={{ __html: option.title }}
        ></li>
      )}
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      sx={{
        width: "100%",
        "& .MuiAutocomplete-popupIndicator": { transform: "none" },
      }}
    />
  );
};

export default GroupedAutocomplete;