import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Logo from './../../assets/images/logo.png';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { CircularProgress, Paper } from '@mui/material';
import { loginRequest } from './../../helper/auth';
import { useMsal } from '@azure/msal-react';
import loginImg from "../../assets/images/loginBg.png";

export default function Login(props) {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const { instance, inProgress } = useMsal();
  const activeAccount = instance.getActiveAccount();

  const handleRedirect = () => {
    // if (inProgress === "none") {
    instance.loginRedirect({
      ...loginRequest,
      redirectUri: "/",
      prompt: 'create',
    }).catch((error) => console.log(error));
    // }
  };

  return (
    <>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <>
          <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${loginImg})`,
                backgroundSize: 'cover',
                backgroundPosition: 'left',
                objectFit: "cover",
              }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#003968',
                opacity: '70%',
                height: '100%'
              }}>
                <img src={Logo} style={{ width: '60%', opacity: 1 }} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#E9F6FF'
            }}>
              <Box
                sx={{
                  mt: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: "center",
                  alignItems: 'center',
                  width: '80%'
                }}
              >
                <div style={{
                  color: '#4A4A4A',
                  fontFamily: 'Oswald-Regular',
                  fontSize: '32px',
                  fontStyle: 'normal',
                  fontWeight: "700",
                  lineHeight: '32px',
                  letterSpacing: '0.4px'
                }}>
                  Log in to your Account
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 6, borderRadius: '100px', ':hover': { bgcolor: theme.palette.primary.main, color: 'white' } }}
                  onClick={handleRedirect}
                  size='large'
                >
                  Login
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      )
      }
    </>
  );
}