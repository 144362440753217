import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchFavoriteApps,
  removeFavorite,
  updateFavoriteOrder,
} from "./../../redux/actions/graph/favorites";
import styles from "./styles";
import Loader from "../../components/Loader";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { arrayMove } from "@dnd-kit/sortable";
import SortableAppCard from "../../components/AppCard/SortableAppCard";
import { getUserData } from "../../redux/actions/userData/userData";
import { getApplicationsAssignedToUser } from "../../redux/actions/graph";

function FavoriteApps(props) {
  const dispatch = useDispatch();
  const { classes } = styles();
  const apps = useSelector((state) => state.apps.apps);
  const loading = useSelector((state) => state.getFavorites.loading);
  const favoritesApps = useSelector((state) => state.getFavorites);
  const userId = useSelector((state) => state.userData.userData.id);
  const userData = useSelector((state) => state.userData);
  const { isDashboard } = props;

  useEffect(() => {
    if (apps.length === 0) dispatch(getApplicationsAssignedToUser(userId));
  }, [dispatch, userData, apps.length]);

  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  useEffect(() => {
    if (userId) dispatch(fetchFavoriteApps(userId));
  }, [dispatch, userId]);

  const uniqueApps = new Map();
  apps.forEach((entry) => {
    if (entry != undefined && !uniqueApps.has(entry.appId)) {
      uniqueApps.set(entry.appId, entry);
    }
  });

  const filteredAppsData = Array.from(uniqueApps.values());

  function FilterFavoritesApps(appsData, favoritesData) {
    const filterFavoritesApps = [];
    favoritesData?.getFavorites?.forEach((favoriteItem) => {
      favoriteItem.favorites.forEach((appId) => {
        const matchingApp = appsData.find((app) => app.appId === appId);
        if (matchingApp) {
          const mergedData = { ...matchingApp, ...favoriteItem };
          filterFavoritesApps.push(mergedData);
        }
      });
    });
    return filterFavoritesApps;
  }

  const isMobile = useMediaQuery("(max-width:768px)");
  const filterFavoritesApps = FilterFavoritesApps(
    filteredAppsData,
    favoritesApps
  );
  const dashboardFilterFavoritesApps = filterFavoritesApps.slice(0, 8);
  const MobileDashboardFilterFavoritesApps = filterFavoritesApps.slice(0, 2);

  const handleFavoriteChangeInFavorites = (appId, isFav) => {
    if (isFav) {
      dispatch(removeFavorite({ userId, appId })).then((res) => {
        if (!res.payload.error) {
          dispatch(fetchFavoriteApps(userId));
        }
      });
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = filterFavoritesApps.findIndex(
        (item) => item.appId === active.id
      );
      const newIndex = filterFavoritesApps.findIndex(
        (item) => item.appId === over.id
      );

      if (oldIndex !== -1 && newIndex !== -1) {
        const newItems = arrayMove(filterFavoritesApps, oldIndex, newIndex);
        dispatch(
          updateFavoriteOrder({ userId, appId: active.id, newItems })
        ).then((res) => {
          if (!res.payload.error) dispatch(fetchFavoriteApps(userId));
        });
      } else {
        console.error("Could not find items for drag end event");
      }
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 0.01,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  return (
    <>
      {loading ? (
        <Loader isDashboard={isDashboard} />
      ) : isDashboard ? (
        <Grid
          container
          className={
            dashboardFilterFavoritesApps.length === 0
              ? isMobile
                ? classes.favMobileDashboardContainerNoApps
                : classes.favMobileDashboardContainer
              : isMobile
              ? classes.favMobileDashboardContainer
              : classes.favDashboardContainer
          }
        >
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Grid
              item
              md={12}
              className={
                isMobile
                  ? classes.favMobileAppCardContainer
                  : classes.favAppCardContainer
              }
            >
              {dashboardFilterFavoritesApps.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4>No favorites app currently in your list.</h4>
                </Box>
              ) : (
                <>
                  {isMobile ? (
                    <SortableContext
                      items={
                        MobileDashboardFilterFavoritesApps &&
                        MobileDashboardFilterFavoritesApps.map(
                          (app) => app.appId
                        )
                      }
                      strategy={rectSortingStrategy}
                    >
                      {MobileDashboardFilterFavoritesApps &&
                        MobileDashboardFilterFavoritesApps.map((app, index) => {
                          if (app.appId !== null) {
                            return (
                              <SortableAppCard
                                key={app.appId}
                                app={app}
                                userId={userId}
                                isFavorite={true}
                                isDashboard={isDashboard}
                                onFavoriteChange={
                                  handleFavoriteChangeInFavorites
                                }
                              />
                            );
                          }
                        })}
                    </SortableContext>
                  ) : (
                    <SortableContext
                      items={
                        dashboardFilterFavoritesApps &&
                        dashboardFilterFavoritesApps.map((app) => app.appId)
                      }
                      strategy={rectSortingStrategy}
                    >
                      {" "}
                      {dashboardFilterFavoritesApps &&
                        dashboardFilterFavoritesApps.map((app, index) => {
                          if (app.appId !== null) {
                            return (
                              <SortableAppCard
                                key={app.appId}
                                app={app}
                                userId={userId}
                                isFavorite={true}
                                isDashboard={isDashboard}
                                onFavoriteChange={
                                  handleFavoriteChangeInFavorites
                                }
                              />
                            );
                          }
                        })}
                    </SortableContext>
                  )}
                </>
              )}
            </Grid>
          </DndContext>
        </Grid>
      ) : (
        <Grid container className={classes.favContainer}>
          <Link style={{ zIndex: 1 }} to={-1}>
            <div className={classes.backArrow}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  d="M20 11.1562H7.83L13.42 5.56625L12 4.15625L4 12.1562L12 20.1562L13.41 18.7463L7.83 13.1562H20V11.1562Z"
                  fill="#D0D0D2"
                />
              </svg>
              <div className={classes.backTxt}>Back</div>
            </div>
          </Link>
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <Grid
              item
              md={12}
              style={{
                display: "flex",
                flexWrap: "wrap",
                paddingTop: "60px",
              }}
            >
              {filterFavoritesApps.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h4>No favorites app currently in your list.</h4>
                </Box>
              ) : (
                <SortableContext
                  items={
                    filterFavoritesApps &&
                    filterFavoritesApps.map((app) => app.appId)
                  }
                  strategy={rectSortingStrategy}
                >
                  {filterFavoritesApps &&
                    filterFavoritesApps.map((app, index) => {
                      if (app.appId !== null) {
                        return (
                          <SortableAppCard
                            key={app.appId}
                            app={app}
                            userId={userId}
                            isFavorite={true}
                            isDashboard={isDashboard}
                            onFavoriteChange={handleFavoriteChangeInFavorites}
                          />
                        );
                      }
                    })}
                </SortableContext>
              )}
            </Grid>
          </DndContext>
        </Grid>
      )}
    </>
  );
}

export default FavoriteApps;
