import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) =>{
  return {
    feedbackButtonContainer: {
      position: "fixed",
      bottom: "148px",
      right: "-10px",
      zIndex: "999",
      display: "flex",
      width: "32px",
      padding: "24px 8px",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.only('xs')]: {
      right: "0px",
      }
    },
    feedbackButton: {
      backgroundColor: `${theme.palette.primary.main}`,
      "&:hover": { backgroundColor: `${theme.palette.primary.main} !important` },
      transform: "rotate(270deg)",
      textTransform: "capitalize !important",
      cursor: "pointer",
      borderRadius: "2px 2px 0px 0px",
    },
    feedbackText: {
        fontFamily: "Oswald-Regular",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "20px",
      },
  }
}
);

export default styles;
