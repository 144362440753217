import { createSlice } from "@reduxjs/toolkit";
import { getUserData } from "../../actions/userData/userData";

const initialState = {
  userData: [],
  loading: false,
  error: null,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  extraReducers: (builder) => {
    //Get UserDetails
    builder.addCase(getUserData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.loading = false;
      state.userData = action.payload;
      state.error = "";
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.loading = false;
      state.userData = [];
      state.error = action.error.message;
    });
  },
});

export default userDataSlice.reducer;
