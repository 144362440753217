import React, { useState } from "react";
import ReactPlayer from 'react-player/lazy';
import './style.css';
import { Box } from "@mui/material";

export default function VideoViewer({ videoUrl }) {
    return (
        <Box xs={12} sx={{ padding: '0px 60px 60px 60px' }}>
            <div className='player-wrapper'>
                <ReactPlayer
                    controls
                    className='react-player'
                    url={videoUrl}
                    width='100%'
                    height='100%'
                />
            </div>
        </Box>
    )
};