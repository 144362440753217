// searchSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedValues: [],
  checkboxStates: {}, // Object to store checkbox checked states
};

const searchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    addSelectedValue: (state, action) => {
      state.selectedValues.push(action.payload);
    },
    removeSelectedValue: (state, action) => {
      const { payload } = action;
      if (Array.isArray(payload)) {
        // If payload is an array, remove each value from selectedValues
        payload.forEach(value => {
          const index = state.selectedValues.findIndex(existingValue => {
            if (typeof existingValue === "object") {
              return JSON.stringify(existingValue) === JSON.stringify(value);
            }
            return existingValue === value;
          });
          if (index !== -1) {
            state.selectedValues.splice(index, 1);
          }
        });
      } else {
        // If payload is a single value, remove it from selectedValues
        const index = state.selectedValues.findIndex(existingValue => {
          if (typeof existingValue === "object") {
            return JSON.stringify(existingValue) === JSON.stringify(payload);
          }
          return existingValue === payload;
        });
        if (index !== -1) {
          state.selectedValues.splice(index, 1);
        }
      }
      // Check if all remaining selectedValues are not strings, reset if true
      if (!state.selectedValues.some(item => typeof item === 'string')) {
        state.selectedValues = [];
      }
    },
    toggleCheckboxState: (state, action) => {
      state.checkboxStates[action.payload] =
        !state.checkboxStates[action.payload];
    },
    initializeCheckboxStates: (state, action) => {
      state.checkboxStates = action.payload;
    },
    clearSelectedValues(state) {
      state.selectedValues = [];
    },
  },
});

export const {
  addSelectedValue,
  removeSelectedValue,
  toggleCheckboxState,
  initializeCheckboxStates,
  clearSelectedValues
} = searchFilterSlice.actions;

export default searchFilterSlice.reducer;
