import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken, getIdToken } from "../../../helper/auth";
import axios from "axios";
import config from "../../../config";

export const fetchAndStoreToken = createAsyncThunk(
  "oAuth/fetchAndStoreToken",
  async (_, thunkAPI) => {
    try {
      const idToken = await getIdToken();
      const accessToken = await getAccessToken();
      const formData = new FormData();
      formData.append("token", idToken);
      formData.append("access_token", accessToken);
      const headers = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${config.drupalapiBaseUrl}/api/authorize_api`,
        formData,
        headers
      );

      localStorage.setItem("oAuthToken", response.data.token);

      sessionStorage.setItem("refreshDialog", "false");

      if (response && !response.data.status)
        sessionStorage.setItem("sessionDialog", "true");
      else sessionStorage.setItem("sessionDialog", "false");

      return response.data.token;
    } catch (error) {

      if (error.response.status)
        sessionStorage.setItem("refreshDialog", "true");
      
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
