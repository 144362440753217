import { createSlice } from "@reduxjs/toolkit";
import { getFeedback } from "../../actions/Feedback/feedback";

const initialState = {
  loading: false,
  error: false,
  feedback: [],
  message: null,
};

const feedbackSlice = createSlice({
  name: "feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeedback.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeedback.fulfilled, (state, action) => {
      state.loading = false;
      state.feedback = action.payload;
      state.error = "";
    });
    builder.addCase(getFeedback.rejected, (state, action) => {
      state.loading = false;
      state.feedback = [];
      state.error = action.error.message;
    });
  },
});

export default feedbackSlice.reducer;
