import React, { useEffect } from 'react';
import "./style.scss";

const Chatbot = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_CHATBOT_URL || "https://cira-mt-prod01-bots01-stage.cognicor.com/va/6513cf5aab6ad739b07ecbc6/cira-va-es5.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <cira-va 
      project-id={process.env.REACT_APP_CHATBOT_PROJECT || "6513cf5aab6ad739b07ecbc6"} 
      va-ui-settings="mirador"  
      sso-login-type="popout"
    ></cira-va>
  );
};

export default Chatbot;