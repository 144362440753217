import { createSlice } from "@reduxjs/toolkit";
import { postFeedback } from "../../actions/Feedback/feedbackPost";

const initialState = {
  loader: false,
  error: false,
  feedbackPost: [],
  message: null,
};

const feedbackPostSlice = createSlice({
  name: "feedbackPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postFeedback.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(postFeedback.fulfilled, (state, action) => {
      state.loader = false;
      state.feedbackPost = action.payload;
      state.error = "";
    });
    builder.addCase(postFeedback.rejected, (state, action) => {
      state.loader = false;
      state.feedbackPost = [];
      state.error = action.error.message;
    });
  },
});

export default feedbackPostSlice.reducer;
