import {createSlice} from '@reduxjs/toolkit'
import { getAutoComplete } from '../../actions/Autocomplete/autocomplete'

const initialState = {
    loading: false,
    error: false,
    autoComplete:[],
    message: null,
  };

const autocompleteSlice = createSlice({
    name:'autoComplete',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Notice
      builder.addCase(getAutoComplete?.pending, (state) => {
        state.loading = true;
        state.autoComplete = [];
        
      });
      builder.addCase(getAutoComplete?.fulfilled, (state, action) => {
        state.loading = false;
        state.autoComplete = action.payload;
        state.error = "";
      });
      builder.addCase(getAutoComplete?.rejected, (state, action) => {
        state.loading = false;
        state.autoComplete = [];
        state.error = action.error.message;
      });
    }
})

export default autocompleteSlice.reducer