import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import styles from "./styles";
import { Link } from "react-router-dom";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";

const ImportantUpdates = ({ importantUpdates, companyNews }) => {
  const [importantUpdatesData, setImportantUpdatesData] = useState();
  const { loading } = useSelector((state) => state.notice);
  const theme = useTheme();
  const { classes } = styles();

  useEffect(() => {
    if (importantUpdates.length > 0) {
      const sortedImportantUpdates = importantUpdates
        .map((importantUpdates) => ({
          ...importantUpdates,
          noticeboard_weight: parseFloat(importantUpdates.noticeboard_weight),
        }))
        .sort((a, b) => a.noticeboard_weight - b.noticeboard_weight);
      setImportantUpdatesData(sortedImportantUpdates);
    }
  }, [importantUpdates]);

  const renderImportantUpdatesData = (el) => {
    const title = el.noticeboard_title;
    const summary = el.noticeboard_summary;
    const weight = el.noticeboard_weight;
    const linkText = el.noticeboard_button_label;
    const link = el.detail_page_api_link;
    return (
      <ListItem
        key={weight}
        sx={{ paddingTop: "0", marginBottom: theme.spacing(2) }}
      >
        {" "}
        <ListItemText
          sx={{ margin: "0" }}
          primary={
            <React.Fragment>
              <Typography
                className={classes.title}
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              ></Typography>
            </React.Fragment>
          }
          secondary={
            <React.Fragment>
              <Typography className={classes.description}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: summary,
                  }}
                ></span>
                <Link
                  to={link}
                  state={el}
                  className={classes.linkText}
                  dangerouslySetInnerHTML={{
                    __html: "  " + "Read More",
                  }}
                ></Link>
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
    );
  };
  return (
    <Box
      className={classes.importantUpdatesBox}
      style={{
        height: importantUpdates.length > companyNews.length ? "auto" : "100%",
      }}
    >
      <List
        subheader={
          <ListSubheader className={classes.importantUpdatesHeader}>
            <div className={classes.icon}>
              <AssignmentOutlinedIcon />
            </div>
            <div className={classes.importantUpdatesHeaderText}>
              Important Updates
            </div>
          </ListSubheader>
        }
      >
        {loading ? (
          <Loader />
        ) : (
          importantUpdatesData &&
          importantUpdatesData.map((el) => renderImportantUpdatesData(el))
        )}
      </List>
    </Box>
  );
};

export default ImportantUpdates;
