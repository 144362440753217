import { LogLevel, InteractionRequiredAuthError } from "@azure/msal-browser";
import { PublicClientApplication } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_IDENTITY_CLIENT_ID || "940fa2bc-f4b9-4320-b688-687318fea443",
    authority:
      "https://login.microsoftonline.com/6ab7bf81-0311-401d-87ae-6508f1b32309",
    redirectUri: "/",
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        if (
          level === LogLevel.Info &&
          message.includes("msal:initializeStart")
        ) {
          return;
        }
      },
      logLevel: LogLevel.Info,
    },
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

const refreshDelay = 50 * 60 * 1000;

setInterval(async () => {
  try {
    await getAccessToken(true);
  } catch (error) {
    console.error("Failed to refresh token:", error);
  }
}, refreshDelay);



export const getAccessToken = async (forceRefresh = false) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) throw new Error("No account found");

  const silentRequest = {
    scopes: ["openid", "profile", "user.read"],
    account: accounts[0],
    forceRefresh: forceRefresh,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.error("Silent token acquisition failed:", error);

      // Fallback to interactive token acquisition
      try {
        const interactiveResponse = await msalInstance.acquireTokenPopup({
          scopes: ["openid", "profile", "user.read"],
        });
        return interactiveResponse.accessToken;
      } catch (interactiveError) {
        console.error(
          "Interactive token acquisition failed:",
          interactiveError
        );
        throw interactiveError;
      }
    } else {
      throw error;
    }
  }
};

export const getIdToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) throw new Error("No account found");

  const silentRequest = {
    scopes: ["openid", "profile", "user.read"],
    account: accounts[0],
  };

  try {
    const response = await msalInstance.acquireTokenSilent(silentRequest);
    return response.idToken;
  } catch (error) {
    console.error("Silent token acquisition failed:", error);

    // Fallback to interactive token acquisition
    try {
      const interactiveResponse = await msalInstance.acquireTokenPopup({
        scopes: ["openid", "profile", "user.read"],
      });
      return interactiveResponse.idToken;
    } catch (interactiveError) {
      console.error("Interactive token acquisition failed:", interactiveError);
      throw interactiveError;
    }
  }
};

export const loginRequest = {
  scopes: ["user.read"],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

// Initialize MSAL instance
msalInstance
  .initialize()
  .then(() => {
    console.log("MSAL initialized");
  })
  .catch((error) => {
    console.error("MSAL initialization failed:", error);
  });
