import {createSlice} from '@reduxjs/toolkit'
import { getSiteAlerts } from '../../actions/siteAlerts/siteAlerts';

const initialState = {
    loading: false,
    error: false,
    siteAlerts:[],
    message: null,
  };

const siteAlertsSlice = createSlice({
    name:'siteAlerts',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get site Alerts
      builder.addCase(getSiteAlerts.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getSiteAlerts.fulfilled, (state, action) => {
        state.loading = false;
        state.siteAlerts = action.payload;
        state.error = "";
      });
      builder.addCase(getSiteAlerts.rejected, (state, action) => {
        state.loading = false;
        state.siteAlerts = [];
        state.error = action.error.message;
      });
    }
})

export default siteAlertsSlice.reducer