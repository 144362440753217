import React from "react";
import { Card, CardContent, Box, useMediaQuery } from "@mui/material";
import styles from "./styles";
import { msalConfig } from "./../../helper/auth";
import FavoriteIconButton from "./FavoriteIconButton";

function AppCard(props) {
  const { app, isFavorite, onFavoriteChange, handleCardClick, isDashboard } =
    props;
  const { classes } = styles();
  const isMobile = useMediaQuery("(max-width:768px)");

  const handleFavoriteClick = () => {
    onFavoriteChange(app.appId, isFavorite); // Notify the parent component of the favorite change
  };

  return (
    <Card
      className={
        isDashboard && isMobile
          ? classes.favoriteDashboardCardRoot
          : classes.favoriteCardRoot
      }
      onClick={handleCardClick}
    >
      <CardContent className={classes.cardContentPadding}>
        <Box className={classes.imgBox}>
          <div style={{ width: "100%", alignContent: "center" }}>
            <a
              href={`https://launcher.myapps.microsoft.com/api/signin/${app.appId}?${msalConfig.auth.tenantId}`}
              target="_blank"
              style={{ textDecoration: "none" }}
              rel="noreferrer"
            >
              <div
                style={{
                  fontFamily: "Montserrat-Regular",
                  lineHeight: "16px",
                  color: "#183750",
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                {app.displayName}
              </div>
            </a>
          </div>
          <div>
            <FavoriteIconButton
              isFavorite={isFavorite} // Pass isFavorite as a prop
              isDashboard={isDashboard}
              onClick={handleFavoriteClick} // Pass the handler function for favorite click
            />
          </div>
        </Box>
        {/* {!isDashboard &&
          <div className={classes.cardTxt}>
            Master the unknown with intelligent automation makes enterprise.
          </div>
        } */}
      </CardContent>
    </Card>
  );
}

export default AppCard;
