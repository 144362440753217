import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    siteAlert: {
      color: theme.palette.info.dark,
      fontFamily: "Montserrat-Regular !important",
      fontSize: "12px !important",
      fontWeight: "400 !important",
      lineHeight: "16px !important",
      letterSpacing: "0.25px !important",
      gap: "6px !important",
      padding: "0px 10px 0px 32px !important",
      justifyContent: "center !important",
      alignItems: "center !important",
      borderRadius: "0 !important",
      marginBottom: '0px !important'
    }
  }
}
);


export default styles;