import {createSlice} from '@reduxjs/toolkit'
import { getNotice } from '../../actions/notice/notice';

const initialState = {
    loading: false,
    error: false,
    notice:[],
    message: null,
  };

const noticeSlice = createSlice({
    name:'notice',
    initialState,
    reducers: { },
    extraReducers: (builder) => {
      //Get Notice
      builder.addCase(getNotice.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getNotice.fulfilled, (state, action) => {
        state.loading = false;
        state.notice = action.payload;
        state.error = "";
      });
      builder.addCase(getNotice.rejected, (state, action) => {
        state.loading = false;
        state.notice = [];
        state.error = action.error.message;
      });
    }
})

export default noticeSlice.reducer