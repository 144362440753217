import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "./../../../helper/auth";
import config from "./../../../config";

export const fetchApps = createAsyncThunk(
  "apps/fetchApps",
  async ({ appRoleId }) => {
    try {
      const accessToken = await getAccessToken();
      const appRoleAssignmentUrl = `${config.graphapiBaseUrl}/servicePrincipals/${appRoleId}??$select=id, appId, displayName, description, notes, info, tags`;
      const headers = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.get(appRoleAssignmentUrl, { headers });
      return response.data;
    } catch (error) {
      console.error("Error fetching Microsoft apps:", error);
    }
  }
);

async function fetchApplicationInfo(dispName) {
  try {
    //const test = 'NetX360+ Web - Pershing';
    // Decode the display name first
    const encodedUrl = dispName.replace(/\+/g, encodeURIComponent('+'));

    // Get the application information for theencodedUrl app role assignment
    const accessToken = await getAccessToken();
    const appRoleAssignmentUrl = `${config.graphapiBaseUrl}applications?$select=id,appId,displayName,description,notes,info,tags&$filter=displayName eq '${encodedUrl}'`;
    
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(appRoleAssignmentUrl, { headers });

    // Extract and return the application information
    return response.data.value[0];
  } catch (error) {
    console.error(
      "Error fetching application info for app role assignment:",
      error.response?.data || error
    );
    throw error;
  }
}

export const getApplicationsAssignedToUser = createAsyncThunk(
  "apps/getApplicationsAssignedToUser",
  async (userId) => {
    try {
      const appRoleAssignments = await fetchAppRoleAssignmentsForUser(userId);

      // Use a set to track unique application IDs
      const uniqueApplicationIds = new Set();
      const applications = [];

      // Fetch application information for each unique app role assignment in parallel
      // const fetchPromises = appRoleAssignments.map(async (assignment) => {
      //   const appRoleId = assignment.resourceId;

      //   // Fetch application information if not already fetched
      //   if (!uniqueApplicationIds.has(appRoleId)) {
      //     uniqueApplicationIds.add(appRoleId);
      //     const applicationInfo = await fetchApplicationInfo(appRoleId);
      //     applications.push(applicationInfo);
      //   }
      // });

      const fetchPromises = appRoleAssignments.map(async (assignment) => {
        const dispName = assignment.resourceDisplayName;
        const appRoleId = assignment.resourceId;
        // Fetch application information if not already fetched
        if (!uniqueApplicationIds.has(appRoleId)) {
          uniqueApplicationIds.add(appRoleId);
          const applicationInfo = await fetchApplicationInfo(dispName);
          applications.push(applicationInfo);
        }
      });

      // Wait for all fetch operations to complete
      await Promise.all(fetchPromises);
      return applications;
    } catch (error) {
      console.error(
        "Error getting applications assigned to user:",
        error.response?.data || error
      );
      throw error;
    }
  }
);


// Function to fetch app role assignments associated with a user ID
async function fetchAppRoleAssignmentsForUser(userId) {
  try {
    const accessToken = await getAccessToken();
    // Get the app role assignments for the user
    const limit = process.env.REACT_APP_APPLICATION_LIMIT || 300;
    const appRoleAssignmentUrl = `${config.graphapiBaseUrl}/users/${userId}/appRoleAssignments?$top=${limit}`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };
    const response = await axios.get(appRoleAssignmentUrl, { headers });
    // Extract and return the app role assignments
    return response.data.value || [];
  } catch (error) {
    console.error(
      "Error fetching app role assignments for user:",
      error.response.data || error
    );
    throw error;
  }
}
