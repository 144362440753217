import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAccessToken } from "./../../../helper/auth";
import config from "./../../../config";

export const getUserData = createAsyncThunk("me", async () => {
  const accessToken = await getAccessToken();
  const apiUrl = `${config.graphapiBaseUrl}/me`;
  const response = await fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const data = await response.json();
  return data;
});
