// src/components/GoogleTagManager.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom"; // Make sure you have react-router-dom installed
import config from "../../config/index";

const GoogleTagManager = () => {
  const location = useLocation(); // Get current location object

  useEffect(() => {
    const gtagId = config.googleTagManagerID; // Your GTM ID from environment variables

    if (!gtagId) {
      console.error("Google Tag Manager ID is missing.");
      return;
    }

    // Create the script tag for gtag.js
    const gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`;

    // Create the inline script to initialize gtag
    const inlineScript = document.createElement("script");
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtagId}');
    `;

    // Append the scripts to the head
    document.head.appendChild(gtagScript);
    document.head.appendChild(inlineScript);

    // Clean up on component unmount
    return () => {
      document.head.removeChild(gtagScript);
      document.head.removeChild(inlineScript);
    };
  }, []);

  useEffect(() => {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null; // This component does not render anything
};

export default GoogleTagManager;
