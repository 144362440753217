import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
  return {
    feedbackCard: {
      height: "270px",
      width: "350px",
      paddingBottom: "20px",
      right: "32px",
      top: "134px",
    },
    feedbackCardContent: {
      fontSize: "14px",
      fontFamily: "Montserrat-Regular",
      fontWeight: "600",
      paddingBottom: "10px",
      width: "330px",
      color: theme.palette.info.main,
      lineHeight: "24px",
    },
    buttonContainer: {
      justifyContent: "end",
      [theme.breakpoints.only("xs")]: {
        justifyContent: "center",
        gap:"12px !important",
        paddingRight: "0px !important",
      },
      paddingRight: "18px !important",
    },
    cancelButton: {
      fontFamily: "Montserrat-Regular !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      lineHeight: "18px !important",
      letterSpacing: "0.5px !important",
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.background.default} !important`,
      borderRadius: "24px !important",
      "&:hover": {
        backgroundColor: `${theme.palette.background.default} !important`,
      },
      textTransform: "capitalize !important",
      border:`1px solid ${theme.palette.common.outline} !important`,
      boxShadow:"none !important"
    },
    submitButton: {
      fontFamily: "Montserrat-Regular !important",
      fontWeight: "500 !important",
      fontSize: "14px !important",
      lineHeight: "18px !important",
      letterSpacing: "0.5px !important",
      backgroundColor: `${theme.palette.primary.main} !important`,
      borderRadius: "24px !important",
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      textTransform: "capitalize !important",
      boxShadow:"none !important"
    },
  }
}
);

export default styles;
