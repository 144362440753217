import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => {
  return {
    title: {
      color: theme.palette.info.dark,
      fontFeatureSettings: "'clig' off, 'liga' off",
      fontFamily: "Oswald-Regular !important",
      fontSize: "16px !important",
      fontWeight: "700 !important",
      lineHeight: "24px !important",
      letterSpacing: "0.5px",
    },
    description: {
      color: theme.palette.info.dark,
      fontFamily: "Montserrat-Regular !important",
      fontSize: "12px !important",
      fontWeight: "400 !important",
      lineHeight: "16px !important",
      letterSpacing: "0.5px",
    },
    clearAll: {
      color: theme.palette.primary.main,
      fontFamily: "Montserrat-Regular !important",
      fontSize: "12px !important",
      fontWeight: "400 !important",
      lineHeight: "24px !important",
      textAlign: "right",
      letterSpacing: "0.5px",
    },
    dateTime: {
      color: theme.palette.info.light,
      fontFamily: "Montserrat-Regular !important",
      fontSize: "12px !important",
      fontWeight: "400 !important",
      lineHeight: "24px !important",
      letterSpacing: "0.5px",
    },
  };
});

export default styles;
