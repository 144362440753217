import React from 'react';
import { useTheme } from '@mui/material/styles';
import styles from "./styles";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider } from '@mui/material';


const FullScreenDialog = ({ open, onClose, content, title, save, favorites }) => {
	const theme = useTheme();
	const {classes} = styles();

	return (
		<Dialog fullScreen open={open} onClose={onClose} className={favorites ? classes.favDialog : classes.dialog}>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title" className={classes.title}>
				{title}
			</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			{favorites && <Divider style={{width:'100%'}} aria-hidden="true"/>}
			<DialogContent>
				{content}
			</DialogContent>
			<DialogActions>
				<Button autoFocus onClick={onClose}>
					{save}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default FullScreenDialog;
