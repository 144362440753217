import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) => {
    return {
        sliderContainer: {
            // display:'block',
            paddingLeft: '130px',
            paddingTop: '20px',
            "& .slick-prev": {
                left: '-5% !important',
                zIndex: 1
            },
            "& .slick-next": {
                right: '5% !important',
                zIndex: 1
            },
            "& .slick-prev:before": {
                content: "none",
            },
            "& .slick-next:before": {
                content: 'none',
            },
            "& .slick-list": {
                // display: "flex",
                height: '180px'
            }
        },
        content: {
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: theme.spacing(0),
            marginLeft: theme.spacing(0),
            [theme.breakpoints.down("md")]: {
                paddingTop: theme.spacing(2),
                paddingLeft: theme.spacing(8),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(8),
            },
            "& h1": {
                [theme.breakpoints.down("md")]: {
                    paddingTop: theme.spacing(0),
                    marginTop: theme.spacing(0),
                    fontSize: "32px",
                    lineHeight: "40px",
                },
            },
            "& a": {
                TextDecoration: "none !important",
            },
        },
        linkStyle: {
            textDecoration: 'none'
        },
        heading: {
            textAlign: "left",
            //margin: "54px 0 0 20px",
            letterSpacing: "2.4px",
        },
        description: {
            fontFamily: "Montserrat-Regular",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "32px",
            textAlign: "left",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            [theme.breakpoints.down("md")]: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 6,
                WebkitBoxOrient: "vertical",
                lineHeight: "28px",
            },
        },
        btn: {
            display: "flex !important",
            justifyContent: "center !important",
            alignItems: "center !important",
            borderRadius: "31px !important",
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            textAlign: "center !important",
            fontFamily: "Montserrat-Regular !important",
            fontSize: "16px !important",
            fontWeight: "600 !important",
            lineHeight: "16px !important",
            letterSpacing: "0.1px !important",
            textDecoration: "none",
            //marginTop: "24px !important",
            //marginLeft: "75px !important",
        },
        btnArrow: {
            background: theme.palette.primary.main,
            color: theme.palette.common.white,
            display: "flex",
            padding: "6px 12px 6px 8px",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            textTransform: "capitalize",
            borderRadius: "31px !important",
            "& span": {
                TextDecoration: "none !important",
            },
        },
        dots: {
            position: "absolute",
            bottom: "16px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: "1",
        },
        dotsList: {
            display: "inline-flex",
            margin: "0px",
            padding: "0px",
        },
        cardSize: {
            maxWidth: 345,
            height: 150,
        },
        relatedContentCrdTxt: {
            color: theme.palette.info.main,
            letterSpacing: "1px",
        },
        relatedContentCrdHeading: {
            color: "#183750",
            fontFamily: "Oswald-Regular !important",
            fontWeight: "900 !important",
            lineHeight: "24px !important",
            fontSize: "16px !important",
            width: "233px !important",
        },
        cardBackcolor: {
            backgroundColor: "#cdebff4d"
        },
    }
}
);

export default styles;
