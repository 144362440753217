import { makeStyles } from 'tss-react/mui';

const styles = makeStyles()((theme) =>{
  return {
    root: {
      display: 'flex',
      width:'auto',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: theme.palette.secondary.main,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    logo: {
      width: '150%',
    },
    social: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      [theme.breakpoints.only('xs')]: {
        justifyContent: 'center',
        textAlign: 'center',
      },
    },
    follow: {
      color: theme.palette.common.white,
      fontSize: '12px',
      fontWeight: 'normal',
    },
    icons: {
      paddingLeft: theme.spacing(1),
    },
    copyWrite: {
      color: theme.palette.common.white,
      fontWeight: '400',
      fontSize: '12px',
      fontFamily: "Montserrat-Regular",
      [theme.breakpoints.down('sm')]: {
        textAlign: "center",
      },
    }
  }
}
);


export default styles;